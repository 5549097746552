import React from 'react'
import Container from 'components/Container'
import { Layout } from 'components/common'
import SEO from 'components/common/SEO'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" description="Page Not Found" />
    <Container constraints="narrow" className="four-o-four">
      <h1>404</h1>
      <p className="subhead">Something's wrong.</p>
      <p>
        You just hit a page that doesn&#39;t exist. Just <a href="/">go back</a> or let's us now how we can help you
        contacting the Help Center at <a href="mailto:help@go2morganstate.org">help@go2morganstate.org</a>.
      </p>
    </Container>
  </Layout>
)

export default NotFoundPage
